<template>
<div>
  <post1 :json="json" accroche="Avantages et méthodes" date="" auteur="" fonction="" avatar="" comments="" like="" xml="" authorinfo="" />
</div>
</template>


<script>
const eurocurrency = new Intl.NumberFormat('fr-FR',
{
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2
});

import post1 from "@/components/post1.vue";


export default
{
  name: 'sci',
  components:
  {
    post1
  },
  props:
  {},
  data: () => (
  {

  json:"1",
    xml: `
          <?xml version="1.0"?>
            <blogs>
          <titre> Tokeniser une SCI </titre>
          <soustitre>  (Société Civile Immobilière)</soustitre>
          <illustration>https://media.berlcoin.fr/20945479.jpg</illustration>
                <texte>
                La tokenisation d'une SCI est un processus assez simple puisque la partie la plus contraignante est la modification des statuts.

                Ensuite, le logiciel app.fraction permet de gérer l'ensemble de la tokenisation sans difficulté particulière et permet à la SCI de se financer plus facilement, d'être plus attrayant pour les investisseurs, de mobiliser plus de capitaux à de meilleures conditions et de développer son patrimoine immobilier plus rapidement.
                Modification statutaire
              </texte>
          <category>

          <libelle>Modification statutaire</libelle>
          <cat_id>IM</cat_id>


            <blog>
          <reference>R1</reference>
          <titre>Capital</titre>
          <texte>

          Rendre le capital variable : convoquer une Assemblé Générale pour modifier le capital social de la SCI afin de le rendre variable et d'attribuer à chaque part sociale un faible montant (par exemple 1 ou 10 euros).
          Chaque associé devra conserver une part non tokenisée après la tokenisation du capital.

          </texte>
          </blog>

          <blog>
        <reference>R2</reference>
        <titre>Vente</titre>
        <texte>
Autoriser la vente de part sociale sans autorisation préalable : vous devez préciser dans les statuts que les associés acceptent que d'autres associés revendent leur part sociale à des tiers sans que cela ne nécessite une décision en assemblée générale.

        </texte>
        </blog>




          </category>
          <category>
          <libelle>Token</libelle>
          <cat_id>TC</cat_id>

                    <blog>
                    <reference>R3</reference>
                    <titre>Émission de token</titre>
                    <texte>
    La sci va créer des tokens dont le nombre est exactement le même que le nombre de parts sociales.
                    </texte>
                    </blog>

          <blog>
          <reference>R3</reference>
          <titre>Rachat de parts</titre>
          <texte>
La Sci va racheter elle-même toutes les parts sociale sauf une par associé. En contrepartie d'une part sociale rachetée, elle va transférer un token sur le compte de l'associé qui apporte sa part. Au final, la SCI conserve en propre au minimum un token par associé et chaque associé conserve au minimum une part sociale.

          </texte>
          </blog>


          </category>


          <category>

          <libelle>Blockchain</libelle>
          <cat_id>BC</cat_id>

          <blog>
          <reference>R2</reference>
          <titre>  Vente et transfert</titre>
          <texte>
Chaque propriétaire d'un token en dispose à discrétion dans son Wallet et peut donc le vendre ou le transférer à un tiers sur un marché secondaire.
          </texte>
          </blog>


          <blog>
          <reference>R2</reference>
          <titre> Ligne de confiance</titre>
          <texte>
La SCI va créer une ligne de confiance dans la blockchain pour chaque associé qui possède au moins une part sociale.
          </texte>
          </blog>

                <blog>
                <reference></reference>
                <titre>Garanti de rachat</titre>
                <texte>
La Sci garantie le rachat d'un token contre une part sociale à tout associé qui désire récupérer sa part sociale : le token est alors conserver par la sci.

              </texte>
                </blog>



          </category>



          <category>


          <libelle>Gestion</libelle>
          <cat_id>MT</cat_id>




          <blog>
          <reference></reference>
          <titre>Votation</titre>
          <texte>
Avant l'assemblée générale, le gestionnaire de la SCI émet des tokens de vote dans un smart contrat ainsi que les lignes de confiance, chaque associé peut alors valider une transaction de vote au plus tard au moment du vote final. Une fois le vote réalisé, le gestionnaire supprime les lignes de confiance et le vote est clos.
          </texte>
          </blog>



          <blog>
          <reference></reference>
          <titre>Émission ou distribution</titre>
          <texte>
Selon les modalités statutaires, les bénéfices de la SCI sont soit répartis entres associés, soit la SCI conserve les revenus et peut on non emmètre de nouveaux tokens qui sont distribués aux associés. Les revenus locatifs servent dans ce cas à augmenter le capital.
          </texte>
          </blog>



          </category>




          </blogs>


          `,


  }),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed:
  {},
  methods:
  {},
  watch:
  {}
}
</script>

<style scoped>
</style>
